var communiacsModules = ( function( communiacsModules ) {
  'use strict';

  if(!$) {
    throw new Error('jQuery is required.');
  }

  $(document).ready(function() {
    $('.module-image-preloaded .image-preloaded__placeholder img').each(function() {
      $(this).closest('.module-image-preloaded').find('.image-preloaded__loaded').append('<img src="'+$(this).attr('data-src')+'">');
    });

    $('.page').imagesLoaded().progress(function(instance, image) {
      $(image.img).addClass('-loaded');

      if($(image.img).parent().hasClass('image-preloaded__loaded')) {
        $(image.img).closest('.module-image-preloaded').addClass('-loaded');
      }
    });
  });

  return communiacsModules;

})( communiacsModules || {} );
